import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';
import IndyLink from 'components/IndyLink';

import './racing.scss';

import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import will from 'media/pages/racing/WillDendyInSuit.jpeg';

export default function RacingPage({data}){
    const pagePath = "/racing";

    let images = getImages();

    const previewImg = {
        url: images.meta.childImageSharp.fixed.src,
        height: images.meta.childImageSharp.fixed.height,
        width: images.meta.childImageSharp.fixed.width,
        alt: "Will Dendy in SEAT Sport UK car"
    }

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="Racing - Mashoom"
                description="Mashoom sponsors Will Dendy, racing for SEAT Sport UK"
                previewImg={previewImg}
                path={pagePath}
            />
            <PagePicTitle
                imageSrc={will}
                imageAlt="Will Dendy in SEAT Sport UK car">
                    <h1>Racing Sponsorship</h1>
                    <p>Mashoom was born out of motor sport and we are really excited to be sponsoring Will Dendy for 2020, across his racing for SEAT Sport UK as well as his simulator racing and commentating.</p>
            </PagePicTitle>

            <div id="container">
                <Section>
                    <h2>Will Dendy</h2>
                    <p>
                        After working up through the ranks in the highly competitive world of karting, Will was given the opportunity to race in cars by Pirelli, winning their talent competition in 2014. At his first race at Brands Hatch he charged through the field and won from the back of the grid, quickly moving to Aston Martin GT in late 2014. Will has taken some down time since then but is delighted to be back and partnering with Mashoom for his exciting journey with SEATSport UK, a team steeped in history.
                    </p>
                    <p>
                        Will also is involved simulator racing where he has been highly successful. He is a Casual Apex driver in the Team E-Brit GT series, a Simply Race Endurance Series team owner and an organizer of the SR Classics championship. 
                    </p>
                    <p>
                        Will also commentates on the Karting1.co.uk Alpha Live Superkart championship.
                    </p>

                    <h2>Mashoom's Involvement</h2>
                    <p>
                        Mashoom was founded from a motorsport background and as such it's something close to our heart. Mashoom will be providing financial and technical support, as well as any engineering support we can offer.
                    </p>
                    <p>
                        Mashoom is planning to offer race day hospitality and tickets to testing days to our clients and customers. This will also include high-speed passenger rides with Will, a high adrenaline experience! Information on this will be provided as we plan the days, if you are interested please <IndyLink adr="/about/#ContactUs">get in contact</IndyLink>.
                    </p>

                    <h2>Find Out More</h2>
                    <p>
                        We will be posting more information about our results and upcoming events to this page as they come up. For the time being, here are a few links to get more information:
                    </p>
                    <ul>
                        <li><IndyLink adr="https://www.facebook.com/wdendyracing/">Will Dendy's Facebook page</IndyLink></li>
                        <li><IndyLink adr="https://www.msvracing.com/cars/calendar/2020/?c=0&s=11218&d=0">MSVR SuperCup race schedule</IndyLink></li>
                    </ul>
                </Section>
                <Separator />
                <Section>
                    <h2>Snetterton - 4th July 2020</h2>
                    <p>Well we know we are quick! After only a few laps to get this eye in, Will put in a time that saw the team come first in our class and a good way into the two classes above; we qualified second in class A.</p>
                    <p>Alas the car was entered in a different event on the same day and unfortunately, was crashed. We couldn't start our race and therefore didn't get a full result.</p>
                    <p>Welcome to motorsport! These things happens and whilst it's very disappointing, at least we know in future events we have a good shot at bringing home a result with a clean run.</p>
                    <p>Next race; Silverstone :D</p>
                    <Img fluid={images.snetterton_result.childImageSharp.fluid} alt="Snetterton 4th July 2020 Qualifying Results" />
                </Section>
                <Section>
                    <h2>Silverstone - 16th August 2020</h2>
                    <p>Always good to be at Silverstone for whatever reason, even better to be going racing and taking some good friends along as well.</p>
                    <p>Alas the car that we were supposed to be racing was still being repaired after its voyage beyond track limits at Snetterton, however, Will got a drive in another car of the team's.</p>
                    <p>As we have started to get used to, Will put it on pole in qualifying, ~0.35 seconds off the next fastest. Bearing in mind he has never raced in that car, not a bad effort.</p>
                    <p>It then started raining... a lot! After a few safety car laps then a shortened race (certainly didn't last as long as expected anyway), Will pulled in second.</p>
                    <p>Post match analysis found that without our knowledge we had been driving in taxi mode, and the car was under powered for our class as well! So all in, lessons learnt and second will do.</p>
                    <Img fluid={images.silverstone.childImageSharp.fluid} alt="A good day out at Silverstone" />
                </Section>
            </div>

        </PageWrap>
    );
}

function getImages() {
    return useStaticQuery(graphql`
        query RacingPagesImages {
            meta: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/racing/WillDendyInSuit.jpeg"}) {
                childImageSharp {
                    fixed(width: 800, height: 400, fit: CONTAIN, background: "white") {
                        src
                        height
                        width
                    }
                }
            }
            snetterton_result: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/racing/Snetterton-qualifying-result-040720.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            silverstone: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/racing/Silverstone-team-photo-160820.jpg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
}